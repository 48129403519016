import React from "react"
import Header from "../components/layout/header"
import Layout from "../components/layout/layout"
import Footer from "../components/layout/footer"
import Intro from "../components/intro"
import Tabs from "../components/tabs"
import { projectData } from "../data"
import { withPrefix } from "gatsby"
import styled from "styled-components"

const SMainGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media screen and (min-width: 770px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: 1090px) {
    grid-template-columns: 1fr 1fr 1fr;
    // grid-auto-rows: min-content;
    width: 100%;
    margin: 2rem 0 0 0;
    padding: 0;
    grid-gap: 2rem;
  }
`

export default () => {
  return (
    <Layout>
      <Header title="Projects" />
      <Intro
        primary="My"
        secondary="Work"
        align="center"
        tag="Recent projects "
      >
        {/* <Tabs tags={[`React`, `WordPress`, `HTML 5`, `Vue.js`]} /> */}
        <div className="grid-container">
          <SMainGrid>
            {projectData.map(project => {
              return (
                <div className="projects">
                  <div className="image-container">
                    <img
                      src={withPrefix(`${project.Image}`)}
                      alt="Project Image"
                    />
                    <div className="after">
                      <div className="text">
                        <h2>{project.Company}</h2>
                        <p>{project.Stack.map(stack => `${stack} `)}</p>
                        <p class="btn btn-view">
                          <a target="_blank" href={project.URL}>
                            View
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </SMainGrid>
        </div>
      </Intro>
      <Footer />
    </Layout>
  )
}
